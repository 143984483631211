<template>
  <div class="mt-24">
    <div v-scroll-spy="{data: 'div.homepage-selector', offset: checkIsMobile ? 100 : 120}">
      <!-- LOGO -->
      <div class="homepage-selector">
        <div class="w-full min-h-screenMinusHeader flex flex-row" ref="topSection">
          <div class="w-full flex flex-col justify-center items-center">
            <Logo class="-mt-24"/>
            <SloganAbout/>
            <div
                class="mt-16 lg:mt-24 lg:hover:scale-105 transform ease-out duration-200 cursor-pointer hover:opacity-50"
                @click="scrollToSection('whatSection')"
            >
              <base-icon :width="checkIsMobile?24:48" :height="checkIsMobile?24:48" color="fill-redAccentLight" name="chevronDownBig" />
            </div>
          </div>
        </div>
        <HomepageParallax :class="'parallax-tiptoes'"/>
      </div>
      <!--  WHAT? -->
      <div class="homepage-selector">
        <HomepageSection ref="whatSection" id="whatSection">
          <template v-slot:leftSide>
          </template>
          <template v-slot:leftTitle>{{ trans('ABOUT:What') }}</template>
          <template v-slot:leftText><span v-html="trans('ABOUT:WhatText')"></span></template>
          <template v-slot:rightContent><HomepageImage :img="themeImages.aboutVerticalClink"/></template>
        </HomepageSection>
        <HomepageParallax :class="'parallax-roses'"/>
      </div>
      <!--  WHY? -->
      <div class="homepage-selector">
        <HomepageSection ref="whySection" id="whySection">
          <template v-slot:leftSide></template>
          <template v-slot:leftTitle>{{ trans('ABOUT:Why') }}</template>
          <template v-slot:leftText><span v-html="trans('ABOUT:WhyText')"></span></template>
          <template v-slot:rightContent><HomepageImage :img="themeImages.aboutVerticalTouch"/></template>
        </HomepageSection>
        <HomepageParallax :class="'parallax-table'"/>
      </div>
      <!--  HOW WE? -->
      <div class="homepage-selector">
        <HomepageSectionMulti ref="howWeSection" id="howWeSection" center>
          <template v-slot:title>{{ trans('ABOUT:HowWe') }}</template>
          <template v-slot:center>
            <div v-for="(obj, index) in howWeData" :key="index">
              <HomepageSectionMultiTitle center no-select>
                {{ obj.title }}
              </HomepageSectionMultiTitle>
              <HomepageSectionMultiContent :num = "index" :txt = "howWeData[index].text"/>
            </div>
          </template>
        </HomepageSectionMulti>
        <HomepageParallax :class="'parallax-walk'"/>
      </div>
      <!-- HOW TO?  -->
      <div class="homepage-selector">
        <HomepageSectionMulti ref="howToSection" id="howToSection" center>
          <template v-slot:title>{{ trans('ABOUT:HowTo') }}</template>
          <template v-slot:center>
            <div v-for="(obj, index) in howToData" :key="index">
              <HomepageSectionMultiTitle center no-select>
                {{ obj.title }}
              </HomepageSectionMultiTitle>
              <HomepageSectionMultiContent :num = "index" :txt = "howToData[index].text"/>
            </div>
          </template>
        </HomepageSectionMulti>
        <HomepageParallax :class="'parallax-author'"/>
      </div>
      <div class="homepage-selector">
        <!-- AUTHOR 1 -->
        <HomepageSection ref="authorSection" id="authorSection" >
          <template v-slot:leftTitle>{{ trans('ABOUT:Author') }}</template>
          <template v-slot:leftText><span v-html="trans('ABOUT:AuthorText1')"></span></template>
          <template v-slot:rightContent><HomepageImage :img="themeImages.aboutVerticalAuthor"/></template>
        </HomepageSection>
        <!-- AUTHOR 2 -->
        <HomepageSection reverse class="lg:mt-28">
          <template v-slot:leftTitle>...</template>
          <template v-slot:leftText><span v-html="trans('ABOUT:AuthorText2')"></span></template>
          <template v-slot:rightContent><HomepageImage :img="themeImages.aboutVerticalAuthor2" :show="true"/></template>
        </HomepageSection>
        <HomepageParallax :class="'parallax-come'"/>
      </div>
      <!-- WELCOME -->
      <div class="homepage-selector" ref="sub" id="#join-us">
        <div class="w-full min-h-fit flex flex-row">
          <div class="w-full flex justify-center items-center">
            <div class="w-full lg:max-w-6xl flex flex-col px-3 lg:px-6 pt-16 pb-24 lg:pb-60">
              <template v-if="isAppLaunched">
                <div class="w-full px-3 lg:px-6">
                  <div class="font-helvNeu text-4xl lg:text-7xl leading-normal text-redAccentLight mb-16 lg:mb-32 text-center">
                    {{ trans('ABOUT:SubscribeWelcome') }}
                  </div>
                </div>
                <div v-if="userData">
                  <div class="w-full flex flex-col lg:flex-row justify-center items-center gap-6 text-2xl">
                    <Button
                        variant="buttonRedOutlinedLarge"
                        class="uppercase"
                        :to="{name: 'chats'}"
                        :text="trans('BUTTON:GoToChats')"
                    />
                    <Button
                        variant="buttonRedLarge"
                        class="uppercase"
                        :to="{name: 'search'}"
                        :text="trans('BUTTON:GoToNewContacts')"
                    />
                  </div>
                </div>
                <div v-else class="text-center">
                  <div class="mb-6 lg:mb-12 leading-normal font-normal text-xl">
                    <span v-html="trans('ABOUT:SubscribeWelcomeText')">
                    </span>
                  </div>
                  <div class="w-full flex flex-col lg:flex-row justify-center items-center gap-6 text-2xl">
                    <Button
                        variant="buttonRedOutlinedLarge"
                        class="uppercase"
                        :to="{name: 'auth-register'}"
                        :text="trans('BUTTON:Register')"
                    />
                    <Button
                        variant="buttonRedLarge"
                        class="uppercase"
                        :to="{name: 'auth-login'}"
                        :text="trans('BUTTON:LogIn')"
                    />
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="w-full flex flex-col">
                  <div class="mx-auto">
                    <div>
                      <HomepagePageTitle :text="trans('ABOUT:Subscribe')" />
                    </div>
                    <div class="text-center text-xl leading-normal">
                      <span v-html="trans('ABOUT:SubscribeText')">
                      </span>
                    </div>
                    <div class="w-full flex justify-center py-8">
                      <form @submit.prevent="signToNewsletter" class="w-full lg:w-4/5 flex flex-row gap-4 items-end "
                      >
                        <TextField
                            variant="subscribe"
                            field="email"
                            class="w-full"
                            no-error
                            :placeholder="trans('ABOUT:SubscribeEmailPlaceholder')"
                            v-model="email"
                        />
                        <Button
                            variant="buttonRedLarge"
                            class="uppercase"
                            btn-type="submit"
                            @clicked="signToNewsletter"
                            :loading="newsletterLoading"
                            :text="trans('BUTTON:Join')"
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'jquery-parallax.js'
import BaseIcon from "@/utils/icons/BaseIcon";
import Button from "@/utils/crud/components/Button";
import HomepageImage from "@/views/content/components/Homepage/HomepageImage";
import Logo from "@/views/content/components/Homepage/Logo";
import SloganAbout from "@/views/content/components/Homepage/SloganAbout";
import HomepagePageTitle from "@/views/content/components/Homepage/HomepagePageTitle";
import HomepageParallax from "@/views/content/components/Homepage/HomepageParallax";
import HomepageSection from "@/views/content/components/Homepage/HomepageSection";
import HomepageSectionMulti from "@/views/content/components/Homepage/HomepageSectionMulti";
import HomepageSectionMultiContent from "@/views/content/components/Homepage/HomepageSectionMultiContent";
import HomepageSectionMultiTitle from "@/views/content/components/Homepage/HomepageSectionMultiTitle";
import TextField from "@/utils/crud/components/field-types/Text";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import themeConfig from "@/themeConfig";
import {isAppLaunched} from "@/common/helpers/utils";
import {mapGetters, mapActions} from "vuex";

export default {
  name: "Homepage",
  components: {
    BaseIcon,
    Button,
    HomepageImage,
    Logo,
    SloganAbout,
    HomepagePageTitle,
    HomepageParallax,
    HomepageSection,
    HomepageSectionMulti,
    HomepageSectionMultiContent,
    HomepageSectionMultiTitle,
    TextField
  },
  data() {
    return {
      email: null,
      themeImages: themeConfig.staticPhotos,
      checkIsMobile: checkIsMobileDevice(),
      isAppLaunched: isAppLaunched(),
      howWeChosen: "1",
      howWeChosen2: "2",
      howToChosen: "1",
      howWeData: {},
      howToData: {},
    }
  },
  watch: {
    langLoaded: {
      immediate: true,
      handler(val) {
        if (val) {
          if (this.$route.query.error) {
            this.error({message: this.trans(`ALERT:${this.$route.query.error}`)})
          }
          if (this.$route.query.success) {
            this.success({message: this.trans(`ALERT:${this.$route.query.success}`)})
          }

          this.howWeData = {
            1: {
              title: this.trans('ABOUT:HowWeTitle1'),
              text: this.trans('ABOUT:HowWeText1'),
            },
            2: {
              title: this.trans('ABOUT:HowWeTitle2'),
              text: this.trans('ABOUT:HowWeText2'),
            },
            3: {
              title: this.trans('ABOUT:HowWeTitle3'),
              text: this.trans('ABOUT:HowWeText3'),
            },
            4: {
              title: this.trans('ABOUT:HowWeTitle4'),
              text: this.trans('ABOUT:HowWeText4'),
            },
            5: {
              title: this.trans('ABOUT:HowWeTitle5'),
              text: this.trans('ABOUT:HowWeText5'),
            },
            6: {
              title: this.trans('ABOUT:HowWeTitle6'),
              text: this.trans('ABOUT:HowWeText6'),
            },
          }

          this.howToData = {
            1: {
              title: this.trans('ABOUT:HowToTitle1'),
              text: this.trans('ABOUT:HowToText1'),
            },
            2: {
              title: this.trans('ABOUT:HowToTitle2'),
              text: this.trans('ABOUT:HowToText2'),
            },
            3: {
              title: this.trans('ABOUT:HowToTitle3'),
              text: this.trans('ABOUT:HowToText3'),
            },
            4: {
              title: this.trans('ABOUT:HowToTitle4'),
              text: this.trans('ABOUT:HowToText4'),
            },
            5: {
              title: this.trans('ABOUT:HowToTitle5'),
              text: this.trans('ABOUT:HowToText5'),
            },
            6: {
              title: this.trans('ABOUT:HowToTitle6'),
              text: this.trans('ABOUT:HowToText6'),
            },
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters('account', ['userData', 'authDone']),
    ...mapGetters('newsletter', ['newsletterLoading']),
    ...mapGetters('lang', ['langLoaded']),
  },
  methods: {
    ...mapActions('newsletter', ['signToNewsletterRequest']),
    ...mapActions('alert', ['error', 'success']),

    signToNewsletter() {
      this.signToNewsletterRequest({email: this.email})
    },
    scrollToSection(section) {
      let element = document.getElementById(section);
      let headerOffset = this.checkIsMobile ? 180 : 120;
      let elementPosition = element.getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  },
  mounted() {
    $('.parallax-tiptoes').parallax({imageSrc: this.themeImages.aboutWideTiptoes,});
    $('.parallax-author').parallax({imageSrc: this.themeImages.aboutWideAuthor});
    $('.parallax-come').parallax({imageSrc: this.themeImages.aboutWideCome});
    $('.parallax-walk').parallax({imageSrc: this.themeImages.aboutWideWalk});
    $('.parallax-roses').parallax({imageSrc: this.themeImages.aboutWideRoses});
    $('.parallax-table').parallax({imageSrc: this.themeImages.aboutWideTable});

    this.$root.$on('scroll-to-top', () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    })
  },
  beforeDestroy() {
    $('.parallax-mirror').remove()
  }
}
</script>

<style lang="scss">
.prlx-img {
  height: 240px;
}

@media (min-width: 1024px) {
  .prlx-img {
    height: 480px;
  }
}

.menu-link {
  &.active-link {
    opacity: 1;
    color: theme('colors.redAccent')
  }
}

</style>