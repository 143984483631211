<template>
  <div>
    <p class="text-3xl lg:text-4xl text-center leading-tight mt-5 lg:mt-8 font-helvNeu">
      {{ trans('LOGO:Slogan3.1') }} <span class="text-redAccentLight">{{ trans('LOGO:Slogan3.2') }}</span> <span class="whitespace-nowrap">{{ trans('LOGO:Slogan3.3') }}</span>
    </p>
    <p></p>
  </div>
</template>

<script>
export default {
  name: "HomepageSlogan"
}
</script>